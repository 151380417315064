import React, { useState, useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import { Analytics } from "@vercel/analytics/react";
// import dynamic from "next/dynamic";

import Router, { useRouter } from "next/router";
import { ErrorBoundary } from "react-error-boundary";
import fallbackRender from "components/errors/ErrorBoundary";
import { ClerkProvider } from "@clerk/nextjs";

import { Gilroy } from "lib/fonts";
import { iOSUserAgent, detectWebview } from "utils/browsers";
import { CurrencyContext } from "../contexts/currency";
import { UserProvider } from "../contexts/user";
import "../styles/globals.scss";
import "../styles/html-ratio-component.css";
import "../styles/clerk.scss";

export function reportWebVitals(metric) {}

function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = useState(false);
  const defaultCurrency = {
    fullName: "United States Dollar",
    code: "USD",
    symbolCode: 36,
    symbol: "$",
  };
  const [currency, setCurrency] = useState(defaultCurrency);
  const [isiOS, setIsiOS] = useState(false);
  const [isWebview, setIsWebview] = useState(false);

  const changeCurrency = (value) => {
    setCurrency(value);
  };

  useEffect(() => {
    const userAgent = navigator?.userAgent;

    if (!userAgent) {
      return;
    }

    const webviewDetected = detectWebview(userAgent);

    if (webviewDetected) {
      setIsWebview(true);
      sessionStorage.setItem("isWebview", "true");

      const body = document.querySelector("body");
      body.classList.add("wn-is-webview");
      body.classList.add("wn-show-webview-warning");
    }
  }, []);

  useEffect(() => {
    const loadingStatePages = ["experiences"];

    const start = (url, { shallow }) => {
      if (!loadingStatePages.includes(url.replace("/", ""))) {
        return;
      }
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };

    if (iOSUserAgent()) {
      setIsiOS(true);
    }

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  const router = useRouter();
  const currentPath = router.asPath;
  if (
    router.asPath.slice(currentPath.length - 2, currentPath.length) === "#!"
  ) {
    const correctPath = currentPath.slice(0, currentPath.length - 2);

    router.replace(correctPath, correctPath, {
      shallow: false,
    });
    return <></>;
  }

  return (
    <>
      {loading ? (
        <>{/* <PageLoader /> */}</>
      ) : (
        <>
          <Head>
            <meta
              name="viewport"
              content={`width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=${
                isiOS ? "1" : "5"
              }, user-scalable=1`}
            ></meta>
          </Head>

          {/* <Script
            strategy="beforeInteractive"
            src="/scripts/html-ratio.min.js"
          /> */}
          {process.env.NEXT_PUBLIC_ENV === "Production".toLowerCase() ? (
            <Script id="google-tag-manager" strategy="afterInteractive">
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-5R93GTB', );
            `}
            </Script>
          ) : (
            ""
          )}
          {/* Mostly controls popup */}
          {/* <Script src="/scripts/hubspot-scripts.js" strategy="lazyOnload" /> */}

          <CurrencyContext.Provider
            value={{ currency, changeCurrency }}
            initialValue={defaultCurrency}
          >
            <ClerkProvider
              {...pageProps}
              navigate={(to) => router.push(to)}
              appearance={{
                variables: {
                  colorPrimary: "black",
                  colorSuccess: "hsla(216, 100%, 37%, 1)",
                },
                elements: {
                  userButton: {
                    border: "none",
                    primaryColor: "teal",
                    colorTextOnPrimaryBackground: "white",
                  },
                },
              }}
            >
              <UserProvider>
                <ErrorBoundary fallbackRender={fallbackRender}>
                  <div className={`${Gilroy.className}`}>
                    <style jsx global>{`
                      :root {
                        --body-font: ${Gilroy.style.fontFamily} --heading-font:
                          ${Gilroy.style.fontFamily};
                      }
                    `}</style>{" "}
                    <Component {...pageProps} currency={currency} />
                    <Analytics />
                  </div>
                </ErrorBoundary>
              </UserProvider>
            </ClerkProvider>
          </CurrencyContext.Provider>
        </>
      )}
    </>
  );
}

export default MyApp;
