export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function stripHtmlTags(str) {
  return str?.replace(/<[^>]+>/g, "");
}

export function kelvinToCelsius(k) {
  return (k - 273).toFixed(2);
}

// kelvin to fahrenheit
export function kelvinToFahrenheit(k) {
  return ((k - 273) * 1.8 + 32).toFixed(2);
}

export function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function parseToPopulation(str) {
  let parts = str?.split("(")[0].trim("");

  if (parts.includes("million")) {
    parts = (
      parseFloat(str?.split("million")[0].trim("")) * 1000000
    ).toString();
  }

  // remove letters
  return Number(parts?.replace(/[^0-9]/g, ""));
}

export function getCookie(name) {
  var cookieArray = document.cookie.split(";");
  for (var i = 0; i < cookieArray.length; i++) {
    var cookieKeyValuePair = cookieArray[i].split("=");
    if (name === cookieKeyValuePair[0].trim()) {
      return decodeURIComponent(cookieKeyValuePair[1]);
    }
  }
  return null;
}

export function setCookie({ name, data, expires }) {
  // Format the expiration date
  const expirationDate = new Date(expires).toUTCString();

  // Set the cookie
  document.cookie = `${name}=${encodeURIComponent(
    data
  )}; expires=${expirationDate}; path=/`;
}

export function deleteCookie({ name }) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
