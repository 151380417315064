import React, { useState, useEffect } from "react";
import { useAuth } from "@clerk/nextjs";
import { deleteCookie, getCookie, setCookie } from "utils";
import { useRouter } from "next/router";
import { fetchFavorites } from "lib/client-api/user/favorites";
import { fetchCart } from "lib/client-api/user/cart";

// Create the UserContext
export const UserContext = React.createContext();

// Create the UserProvider component
export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    userFavorites: {
      experiences: [],
      activities: [],
      travelGuides: [],
    },
    cart: {
      lastModified: null,
      items: [],
      promoCode: null,
    },
    userId: null,
    locationData: {},
    isUsingWebview: false,
    webviewWarningClosed: false,
  });
  const [initialLoad, setInitialLoad] = useState(false);
  const [isCartUpdating, setIsCartUpdating] = useState(false);

  /* Detect webview */

  useEffect(() => {
    const isWebview = sessionStorage.getItem("isWebview");
    if (isWebview) {
      setUserData((prev) => {
        return { ...prev, isUsingWebview: true };
      });
    }
  }, []);

  const router = useRouter();

  const { userId, isSignedIn } = useAuth();

  useEffect(() => {
    const tempId = getCookie("temp_user_id");

    if (tempId && userId) {
      async function mergeCarts({ tempId, userId }) {
        try {
          async function callMerge() {
            // Merge cart items from temp user with cart items from logged-in user
            const mergeResponse = await fetch("/api/user/cart/merge", {
              method: "POST",
              body: JSON.stringify({ authId: userId, tempUserId: tempId }),
            });
            return mergeResponse;
          }

          const mergeResponse = await callMerge();

          await updateCart();

          deleteCookie("temp_user_id");
        } catch (error) {
          console.error(error);
        }
      }

      mergeCarts({ tempId, userId });
    }

    if (userId) {
      setUserData((prev) => {
        return { ...prev, userId };
      });

      return;
    }

    const tempIdExists = getCookie("temp_user_id");
    const clerkCookieExists = getCookie("clerk_db_jwt");

    if (!!tempIdExists || !!clerkCookieExists) {
      return;
    }

    async function generateUserId() {
      const response = await fetch("/api/utility/randomId");
      const { randomId } = await response.json();

      setUserData((prev) => {
        return { ...prev, userId: `temp-${randomId}` };
      });

      setCookie({
        name: "temp_user_id",
        data: `temp-${randomId}`,
        expires: new Date(Date.now() + 604800000),
      });

      return randomId;
    }

    generateUserId();
  }, [userId, isSignedIn]);

  const updateFavorites = async () => {
    if (!isSignedIn || !userId) return;

    try {
      const fetchedFavorites = await fetchFavorites();

      if (!fetchedFavorites) {
        return;
      }

      const {
        favorites_experiences,
        favorites_viator,
        favorites_travel_guides,
      } = fetchedFavorites;

      if (!!fetchedFavorites) {
        setUserData((prev) => {
          const isDataChanged =
            favorites_experiences?.data?.length !==
              prev.userFavorites.experiences.length ||
            favorites_viator?.length !== prev.userFavorites.activities.length ||
            favorites_travel_guides?.length !==
              prev.userFavorites.travelGuides.length;

          if (!isDataChanged) {
            return prev;
          }

          return {
            ...prev,
            userFavorites: {
              ...prev.userFavorites,
              experiences: [
                // ...prev.userFavorites.experiences,
                ...(favorites_experiences?.data.map((item) => item.id) ?? []),
              ],
              activities: [
                // ...prev.userFavorites.activities,
                ...(favorites_viator ?? []),
              ],
              travelGuides: [
                ...(favorites_travel_guides?.data?.map((item) => item?.id) ??
                  []),
              ],
            },
          };
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      return;
    }

    if (isSignedIn && userId) {
      updateFavorites();
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, userId, router.asPath]);

  function loadCartFromLocalStorage() {
    const localCartData = localStorage.getItem("cart");
    if (!localCartData) {
      return;
    }

    const localCart = JSON.parse(localCartData);

    setUserData((prev) => {
      return {
        ...prev,
        cart: {
          ...prev.cart,
          items: JSON.parse(JSON.stringify(localCart.items)),
          lastModified: localCart.lastModified,
          experiences: localCart.experiences,
        },
      };
    });
  }

  async function updateCart() {
    try {
      setIsCartUpdating(true);
      const currentCart = await fetchCart();

      if (!currentCart) {
        return;
      }

      const { last_modified, experiences, cart_items, promo_code } =
        currentCart;

      const cartItemsObj =
        typeof cart_items === "string" ? JSON.parse(cart_items) : cart_items;

      setUserData((prev) => {
        return {
          ...prev,
          cart: {
            ...prev.cart,
            items: cartItemsObj ?? [],
            lastModified: last_modified,
            experiences: experiences,
            promoCode: promo_code,
          },
        };
      });
      setIsCartUpdating(false);
    } catch (error) {
      console.error("Error fetching user cart:", error);
      setIsCartUpdating(false);
    }
  }

  useEffect(() => {
    if (router.asPath.includes("cart")) {
      return;
    }

    // If cart is in local storage, get preliminary data from that
    loadCartFromLocalStorage();

    // Then, fetch most up-to-date data from Strapi
    updateCart();
  }, [isSignedIn, userId]);

  useEffect(() => {
    if (!router.asPath.includes("cart")) {
      return;
    }

    loadCartFromLocalStorage();

    updateCart();
  }, [router.asPath]);

  // User IP lookup
  useEffect(() => {
    const fetchIP = async () => {
      // Get the user's current IP by fetching from Next.js api route
      const currentIpResponse = await fetch("/api/user/ip");
      const currentIpData = await currentIpResponse.json();
      const currentIp = currentIpData?.ip;

      // Get the user's stored IP from the cookie
      const locationDataCookie = getCookie("locationData") ?? null;

      if (locationDataCookie) {
        const locationData = JSON.parse(locationDataCookie);
        const { ip: storedIp } = locationData;

        // If the user's IP hasn't changed, don't fetch it again
        if (currentIp === storedIp) {
          setUserData((prev) => {
            return { ...prev, locationData };
          });

          return;
        }
      }

      try {
        const response = await fetch("https://ipwho.is/");
        const data = await response.json();

        if (!data || !data?.success) {
          return;
        }

        const {
          ip,
          continent,
          continent_code,
          country_code,
          region,
          region_code,
          city,
          is_eu,
          postal,
          calling_code,
          capital,
          timezone,
          currency,
        } = data;

        const ipData = {
          ip,
          continent,
          continent_code,
          country_code,
          region,
          region_code,
          city,
          is_eu,
          postal,
          calling_code,
          capital,
          timezone,
          currency,
        };

        setCookie({
          name: "locationData",
          data: JSON.stringify(ipData),
          expires: new Date(Date.now() + 604800000),
        });
        setUserData((prev) => {
          return { ...prev, locationData: ipData };
        });
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };

    const locationData = fetchIP();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        refreshFavorites: updateFavorites,
        refreshCart: updateCart,
        isCartUpdating,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
