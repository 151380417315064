"use client";

// import { ErrorBoundary } from "react-error-boundary";
// import ErrorComponent from "./ErrorComponent";

export default function fallbackRender({
  error,
  resetErrorBoundary,
  children,
}) {
  // console.error(error);
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  try {
    resetErrorBoundary();
    return children;
  } catch (error) {
    console.error(error);
    // return <ErrorComponent />;
  }
}
