import React, { createContext } from "react";

const defaultCurrency = {
  fullName: "United States Dollar",
  code: "USD",
  symbolCode: 36,
  symbol: "$",
};

const CurrencyContext = createContext(defaultCurrency);

export { CurrencyContext };
