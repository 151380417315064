import { getCookie } from "utils";

export async function fetchCart() {
  try {
    const tempUserId = getCookie("temp_user_id");

    const response = await fetch(
      `/api/user/cart${tempUserId ? `?tempUserId=${tempUserId}` : ""}`
    );

    const data = await response.json();

    return data;
  } catch {
    return { error: true, message: `Could not retrieve cart for user` };
  }
}
