export async function fetchFavorites() {
  const response = await fetch(
    "/api/user/favorites?idsOnly=true&types=experiences,viator",
    {
      next: {
        revalidate: 0,
      },
    }
  );

  const data = await response.json();

  if (data.status === "401") {
    // console.error("Could not fetch favorites--no logged in user");
    return;
  }

  if (!!data?.userFavorites?.error) {
    // console.error("Could not fetch favorites:", response.error);
    return;
  }

  const fetchedFavorites = await data?.userFavorites?.attributes;

  return fetchedFavorites;
}
